import { FC } from 'react';

import { PageTitleProvider } from '@hooks/pageTitle';
import { useLayoutData } from '@sitecore/common';
import { PLACEHOLDER_APPLICATION_REGEX } from '@sitecore/common/core/AppPlaceholder';

import AppLayout from './AppLayout';
import DefaultLayout from './DefaultLayout';
import { NoGridLayout } from './NoGridLayout';
import { DefaultLayout as SelfServiceColumnLayout } from '../selfservice/DefaultLayout';

export const NO_GRID_REGEX = /jss-no-grid/;
const SITECORE_SINGLE_COLUMN = '90C10083-9F90-4EE2-A2F0-F7DF724C270B';
const SITECORE_TWO_COLUMN = 'ADBA6441-68B1-415E-A64D-B4F31F94C47B';
const SITECORE_THREE_COLUMN = '9006D52D-19CB-4607-97E1-43E3C90353F5';

const Layout: FC = () => {
  const { route } = useLayoutData();

  const hasAppPlaceholder = Object.keys(route.placeholders).find(name => PLACEHOLDER_APPLICATION_REGEX.test(name));
  const isNoGridPlaceholder = Object.keys(route.placeholders).find(name => NO_GRID_REGEX.test(name));

  const isColumnLayout =
    route?.templateId?.toUpperCase() === SITECORE_SINGLE_COLUMN ||
    route?.templateId?.toUpperCase() === SITECORE_TWO_COLUMN ||
    route?.templateId?.toUpperCase() === SITECORE_THREE_COLUMN;

  const pageTitle =
    typeof route?.fields?.pageTitle?.value === 'string' ? route.fields.pageTitle.value : 'Page title missing';
  const pageSubtitle =
    typeof route.fields?.pageSubTitle?.value === 'string' ? route.fields.pageSubTitle.value : undefined;

  if (isColumnLayout) {
    return (
      <PageTitleProvider initialTitle={pageTitle} initialSubtitle={pageSubtitle}>
        <SelfServiceColumnLayout />
      </PageTitleProvider>
    );
  }

  return hasAppPlaceholder ? <AppLayout /> : isNoGridPlaceholder ? <NoGridLayout /> : <DefaultLayout />;
};

export default Layout;
