import React, { createContext, ReactNode, SyntheticEvent, useContext, useEffect, useRef, useState } from 'react';

import { useLinkComponent } from '@link';
import { LinkFieldValue } from '@sitecore-jss/sitecore-jss-react';
import reactToText from 'react-to-text';

import { useMediaQuery } from '../../hooks';
import { keyframes, styled } from '../../stitches.config';
import { TransformStitchesToSparky } from '../../types';
import { mergeRefs } from '../../util';
import { extractVariantProps } from '../../util/css/stitches';

const TileItemContainer = styled('div', {
  padding: '$6',
  position: 'relative',
  width: '100%',
});

const HoverContent = styled('div', {
  transition: 'opacity $easeMedium',
  overflow: 'hidden',
  visibility: 'hidden',
  height: 0,
  width: 0,
  opacity: 0,
});

const popIn = keyframes({
  from: { transform: 'translateY($space$6)' },
  to: { transform: 'translateY(0)' },
});

const StyledTileItem = styled('a', {
  textDecoration: 'inherit',
  color: 'inherit',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  minHeight: '100%',

  'button&': {
    border: 0,
    fontFamily: 'inherit',
    fontSize: 'inherit',
    padding: '0',
  },

  cursor: 'pointer',

  flexWrap: 'wrap',
  width: '100%',
  textAlign: 'center',
  backgroundColor: '$backgroundPrimary',
  //zIndex needed for animating the before element
  zIndex: 1,

  '&:active': {
    backgroundColor: '$backgroundPressed',
  },

  '&::before': {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    content: '',
    backgroundColor: 'inherit',
    borderRadius: 'inherit',
    zIndex: -1,
  },

  '&:hover, &:focus-visible': {
    zIndex: 2,
  },

  '&:focus-visible': {
    outline: 'none',
    '&::before': {
      outline: '$outlineFocus',
    },
  },

  '@supports not selector(:focus-visible)': {
    '&:focus': {
      outline: '$none',
      zIndex: 2,
      '&::before': {
        outline: '$outlineFocus',
      },
    },
  },

  variants: {
    alignY: {
      start: {
        alignItems: 'start',
      },
      center: {
        alignItems: 'center',
      },
      end: {
        alignItems: 'end',
      },
      stretch: {
        alignItems: 'stretch',
      },
      baseline: {
        alignItems: 'baseline',
      },
    },
  },
});

const StyledTileBar = styled('ul', {
  background: '$backgroundPrimary',
  display: 'grid',
  hyphens: 'auto',

  variants: {
    isElevated: {
      true: { boxShadow: '$m', borderRadius: '$m' },
      false: { boxShadow: 'none', borderRadius: 0 },
    },
    direction: {
      row: {
        gridAutoFlow: 'column',
        gridAutoColumns: '1fr',
        [`> li`]: {
          height: 'inherit',
        },
        [`> ${StyledTileItem}`]: {
          height: 'inherit',
        },
      },
      column: {
        gridAutoFlow: 'row',
        gridAutoRows: '1fr',
        [`> li`]: {
          [`> ${StyledTileItem}`]: {
            '&:hover': {
              '&::before': {
                boxShadow: '$shadowHover',
              },
            },
          },
          '&:not(:last-of-type)': {
            [`> ${StyledTileItem}`]: {
              border: 'none',
              borderBottom: '$borderWidths$s solid $borderDividerLowEmphasis',
            },
          },
        },
      },
    },
  },
  compoundVariants: [
    {
      isElevated: true,
      direction: 'column',
      css: {
        [`> li`]: {
          '&:first-of-type': {
            [`> ${StyledTileItem}`]: {
              borderTopLeftRadius: '$m',
              borderTopRightRadius: '$m',
            },
          },
          '&:last-of-type': {
            [`> ${StyledTileItem}`]: {
              borderBottomLeftRadius: '$m',
              borderBottomRightRadius: '$m',
            },
          },
        },
      },
    },
    {
      isElevated: true,
      direction: 'row',
      css: {
        [`> li`]: {
          '&:not(:last-child)': {
            [`> ${StyledTileItem}`]: {
              border: 'none',
              borderRight: '$borderWidths$s solid $borderDividerLowEmphasis',
            },
          },
          '&:first-of-type': {
            [`> ${StyledTileItem}`]: {
              borderTopLeftRadius: '$m',
              borderBottomLeftRadius: '$m',
            },
          },
          '&:last-of-type': {
            [`> ${StyledTileItem}`]: {
              borderTopRightRadius: '$m',
              borderBottomRightRadius: '$m',
            },
          },
        },
        [`> li > ${StyledTileItem}`]: {
          '&::before': {
            borderRadius: '$m',
            '@safeMotion': {
              transition:
                'top $easeMedium, left $easeMedium, right $easeMedium, bottom $easeMedium, box-shadow $easeMedium',
            },
          },
          '&:hover, &:focus-visible': {
            [`> ${TileItemContainer}`]: {
              paddingTop: '0',
              paddingBottom: '0',
              '@safeMotion': {
                animation: `${popIn} $transitions$easeMedium`,
              },
            },
            '&::before': {
              top: '-$6',
              left: '-$2',
              right: '-$2',
              bottom: '-$6',
              boxShadow: '$l',
            },
            [`> ${TileItemContainer} > ${HoverContent}`]: {
              opacity: 1,
              height: 'auto',
              width: 'auto',
              visibility: 'visible',
            },
          },
          '@supports not selector(:focus-visible)': {
            '&:focus': {
              zIndex: 2,
              [`> ${TileItemContainer}`]: {
                paddingBottom: '0',
                paddingTop: '0',

                '@safeMotion': {
                  animation: `${popIn} $transitions$easeMedium`,
                },
              },

              '&::before': {
                top: '-$6',
                left: '-$2',
                right: '-$2',
                bottom: '-$6',
                boxShadow: '$l',
              },
              [`> ${TileItemContainer} > ${HoverContent}`]: {
                opacity: 1,
                display: 'flex',
              },
            },
          },
        },
      },
    },
    {
      isElevated: false,
      direction: 'row',
      css: {
        [`> li > ${StyledTileItem}`]: {
          borderRadius: 0,
          '&::before': {
            borderRadius: 0,
            '@safeMotion': {
              transition: 'none',
            },
          },
          '&:hover, &:focus-visible': {
            '@safeMotion': {
              [`> ${TileItemContainer}`]: {
                animation: 'none',
              },
            },

            '&::before': {
              boxShadow: '$shadowHover',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            },
          },
        },
      },
    },
  ],
  defaultVariants: {
    isElevated: true,
    direction: 'row',
  },
});

type TileBarVariants = TransformStitchesToSparky<typeof StyledTileBar>;

export interface Props extends TileBarVariants {
  children: ReactNode[];
  className?: never;
}

const stitchesClassName = 'sparky-tilebar';

type TileBarContextType = {
  maxHeight: number;
  setMaxHeight: React.Dispatch<React.SetStateAction<number>>;
};

const TileContext = createContext<TileBarContextType>({
  maxHeight: 0,
   
  setMaxHeight: () => {},
});

const useTilebarContext = (): TileBarContextType => useContext(TileContext);

const TileBarComponent = React.forwardRef<HTMLUListElement, Props>(
  ({ children, isElevated = true, direction = 'row' }, ref) => {
    const variantProps = extractVariantProps({ isElevated, direction });
    const [maxHeight, setMaxHeight] = useState(0);

    const sm = useMediaQuery('sm');
    const md = useMediaQuery('md');
    const lg = useMediaQuery('lg');
    const xl = useMediaQuery('xl');
    const breakpoints = { sm, md, lg, xl };
    const isCurrentlyRow =
      direction === 'row' ||
      Object.entries(direction).some(
        // @ts-ignore Object.entries breaks the typing of the element
        element => element[1] === 'row' && element[0] in breakpoints && breakpoints[element[0]],
      );

    return (
      <TileContext.Provider value={{ maxHeight, setMaxHeight }}>
        <StyledTileBar {...variantProps} ref={ref} css={{ height: isCurrentlyRow ? `${maxHeight}px` : undefined }}>
          {children}
        </StyledTileBar>
      </TileContext.Provider>
    );
  },
);

TileBarComponent.toString = () => `.${stitchesClassName}`;

type TileItemVariants = TransformStitchesToSparky<typeof StyledTileItem>;

type TileButtonProps = {
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  linkType?: never;
  linkValue?: never;
};

type TileAnchorProps = {
  type?: never;
  linkValue: LinkFieldValue;
  linkType: LinkFieldValue['linktype'];
};

type ItemProps = {
  onClick?(e: SyntheticEvent): void;
  children: React.ReactNode;
  /** Content shown when a tile is hovered. Only works for the row orientation. */
  hoverContent?: React.ReactNode;
  linkValue?: Pick<LinkFieldValue, 'anchor' | 'href' | 'querystring'>;
  isDisabled?: boolean;
} & TileItemVariants &
  (TileButtonProps | TileAnchorProps);

const TileBarItem = React.forwardRef<HTMLAnchorElement, ItemProps>(
  ({ children, linkType, onClick, isDisabled, type = 'button', hoverContent, alignY = 'center', linkValue }, ref) => {
    const { maxHeight, setMaxHeight } = useTilebarContext();

    const localRef: React.Ref<HTMLButtonElement | HTMLAnchorElement> = useRef(null);
    const mergedRef = mergeRefs([ref, localRef]);

    useEffect(() => {
      const checkHeight = () => {
        if (localRef?.current?.offsetHeight && localRef.current.offsetHeight > maxHeight) {
          setMaxHeight(localRef.current.offsetHeight);
        }
      };

      window.addEventListener('resize', checkHeight);
      checkHeight();
      return () => {
        window.removeEventListener('resize', checkHeight);
      };
    }, [maxHeight, setMaxHeight]);

    const baseProps = { alignY, 'data-label': reactToText(children), onClick };
    const Link = useLinkComponent();

    if (linkValue && linkType) {
      return (
        <li>
          <Link linkValue={linkValue} linkType={linkType}>
            <StyledTileItem
              {...baseProps}
              target={linkValue.target}
              rel={linkValue?.target === '_blank' ? 'noreferrer' : ''}
              ref={mergedRef}>
              <TileItemContainer>
                {children}
                <HoverContent>{hoverContent}</HoverContent>
              </TileItemContainer>
            </StyledTileItem>
          </Link>
        </li>
      );
    }

    const componentProps = { ...baseProps, as: 'button', type };
    return (
      <li>
        <StyledTileItem {...componentProps} ref={mergedRef}>
          <TileItemContainer>
            {children}
            <HoverContent>{hoverContent}</HoverContent>
          </TileItemContainer>
        </StyledTileItem>
      </li>
    );
  },
);

export const TileBar = Object.assign({}, TileBarComponent, { Item: TileBarItem });
