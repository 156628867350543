import React, { createContext, useContext, useState, useCallback, FC, ReactNode, useMemo, useEffect } from 'react';

interface PageTitleContextType {
  title: string | undefined;
  subtitle: string | undefined;
  updateTitle: (newTitle: string) => void;
  updateSubtitle: (newSubtitle: string) => void;
  reset: () => void;
}

const PageTitleContext = createContext<PageTitleContextType | undefined>(undefined);

interface PageTitleProviderProps {
  initialTitle?: string;
  initialSubtitle?: string;
  children: ReactNode;
}

export const PageTitleProvider: FC<PageTitleProviderProps> = ({ initialTitle, initialSubtitle, children }) => {
  const [title, setTitle] = useState<string | undefined>(initialTitle);
  const [subtitle, setSubtitle] = useState<string | undefined>(initialSubtitle);

  const updateTitle = useCallback((newTitle: string) => {
    setTitle(newTitle);
  }, []);

  const updateSubtitle = useCallback((newSubtitle: string) => {
    setSubtitle(newSubtitle);
  }, []);

  const reset = useCallback(() => {
    setTitle(initialTitle);
    setSubtitle(initialSubtitle);
  }, [initialTitle, initialSubtitle]);

  // Reset title and subtitle when initial values change
  useEffect(() => {
    setTitle(initialTitle);
    setSubtitle(initialSubtitle);
  }, [initialTitle, initialSubtitle]);

  const contextValue = useMemo(
    () => ({
      title,
      subtitle,
      updateTitle,
      updateSubtitle,
      reset,
    }),
    [title, updateTitle, subtitle, updateSubtitle, reset],
  );

  return <PageTitleContext.Provider value={contextValue}>{children}</PageTitleContext.Provider>;
};

/**
 * Hook for consuming the PageTitle context
 * @returns Object containing current title and methods to manipulate it
 */
export const usePageTitle = (): PageTitleContextType => {
  const context = useContext(PageTitleContext);

  if (context === undefined) {
    throw new Error('usePageTitle must be used within a PageTitleProvider');
  }

  return context;
};
